import React, { Component } from 'react'
import {loginSuccess, loginFailure, logout} from '../actions/securityActions'
import {loginApi, logoutApi} from '../utils/loginLogoutApiUtil'
import serializeForm from 'form-serialize'
import { connect } from 'react-redux';
import { withRouter , Link} from 'react-router-dom'
import Alert from 'react-s-alert';
import {alertOptions} from '../utils/msgUtil'

class Login extends Component {

    handleSubmit = (e) => {
        e.preventDefault()

        const values = serializeForm(e.target, { hash: true })
        
        loginApi(values)
            .then((response) => {

                console.log(response)

                if (response.error) {
                    Alert.error('Hummm something went wrong when trying to log in',alertOptions);
                    this.props.loginFailure(response.error)
                } else if (response.idToken) {
                    Alert.success('Log in successfully. Good Job!!!',alertOptions);
                    sessionStorage.setItem('gbJwtToken', response.idToken);
                    this.props.loginSuccess(response.idToken)
                    this.cleanForm()
                }

            })
    }

    cleanForm() {
        this.refs.password.value =""
        this.refs.user.value =""
      }

    
      
    logout = () =>{

        logoutApi()
            .then((response) => {
                console.log(response)

                if (response.error) {
                    Alert.error('Hummm something went wrong when trying to log out',alertOptions);
                    //this.props.loginFailure(response.error)
                } else if (response.status) {
                    Alert.success('Log out successfully. Good Job!!!',alertOptions);
                    sessionStorage.removeItem('gbJwtToken');
                    this.props.logout()
                }
            })

    }

    render() {

        return (    
            <div className="w3-col l12 s12">
                {/* <AlertContainer ref={a => this.msg = a} {...alertOptions} /> */}

                <div className="w3-card-4 w3-margin w3-light-grey">
                    <form onSubmit={this.handleSubmit} ref="form" >
                        <div className="w3-container">
                            <div className="w3-row w3-padding-16">
                                <div className="w3-col m4 s12"> 
                                    <input ref="user" name="user" type='text' placeholder='user' className="w3-simple-input" />
                                </div> 
                                <div className="w3-col m4">
                                    <input ref="password" name="password" type='password' placeholder='password' className="w3-simple-input" />
                                </div>          
                                <div className="w3-col m2">
                                    {
                                        !this.props.authenticated && (
                                            <p className='login-button'><button type="submit" className="w3-button w3-padding-large w3-white w3-border">Login</button></p>    
                                        )
                                    }
                                </div>
                                <div className="w3-col m2">
                                    {
                                        this.props.authenticated && (
                                            <p className='logout-button'><button onClick={this.logout.bind(this)} type="button" className="w3-button w3-padding-large w3-white w3-border">Logout</button></p>    
                                        )
                                    }
                                </div>
                            </div>   
                            <div className="w3-row">
                                 <div className="w3-col m1">
                                    <p className="home-button">
                                        <Link to={'/'} >Home</Link>
                                    </p>
                                </div>
                                <div className="w3-col m1"><p className='clear-button'><button type="button" className="w3-button w3-padding-large w3-white w3-border"  >Clear</button></p></div>
                                <div className="w3-col m5 w3-hide-small w3-right">
                                    
                                </div>
                            </div>         
                        </div>
                    </form>
                </div>
                
            </div>
        )
    }

}

const mapStateToProps = ({ comments, posts, categories, security }) => ({
    security
})
 
const mapDispatchToProps = (dispatch)  => (
        {
            loginSuccess:   (values) => dispatch(loginSuccess(values)),
            loginFailure:  (values) => dispatch(loginFailure(values)),
            logout: () => dispatch(logout())
        }
    )

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login))