import * as TYPES from '../actions/actionTypes'

export function security (state = {}, action){
    
    switch (action.type){
        case TYPES.LOGIN_SUCCESS :
            const { apiToken } = action
            return {
                ...state,
                token: apiToken, status:'in', error:null, loading: false
            }
        case TYPES.LOGIN_FAILURE :
            const { error } = action
            return {
                ...state,
                token: null, status:'out', error: error , loading: false
            }
        case TYPES.LOGOUT :
            return {
                ...state,
                token: null, status:'out', error: error , loading: false
            }    
        default :
            return state    
    }
}