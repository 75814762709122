const envURL = `${process.env.REACT_APP_BACKEND}`

export const configURL = envURL || 'http://localhost:3001'

export const HEADERS = { 'Authorization': 'whatever-you-want' , 'content-type': 'application/json'  }

/**
 * Add JWT to Authorization header for authentication
 */
export const addTokenToHeaders = (HEADERS) =>{
    const tokenFromStorage = sessionStorage.getItem('gbJwtToken') || 'noToken'

    return {
        ...HEADERS ,
        Authorization: `Bearer ${tokenFromStorage}`
    }
}