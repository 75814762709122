export function loadState() {
    try {
        const tokenFromStorage = sessionStorage.getItem('gbJwtToken')

        if (tokenFromStorage) {
            return {
                token: tokenFromStorage, status:'in', error:null, loading: false
            }
        }else{
            return {
                token: null, status:'out', error:null, loading: false
            }
        }

    }
    catch (err) {
        return {
            token: null, status:'out', error:null, loading: false
        }
    }
}