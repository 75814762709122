import React, { Component } from 'react'
import {savePost,updatePost,fetchPost} from '../actions/postActions'
import serializeForm from 'form-serialize'
import { connect } from 'react-redux';
import { withRouter , Link} from 'react-router-dom';
import CategorySelect from './CategorySelect'
import Votes from './Votes'
// import { Editor } from 'react-draft-wysiwyg'
// import draftToHtml from 'draftjs-to-html'
// import { EditorState, convertToRaw, ContentState ,convertFromHTML  } from 'draft-js';
// import '../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import Alert from 'react-s-alert';
import {alertOptions} from '../utils/msgUtil'


class PostSave extends Component {


    constructor(props) {
        super(props)
        this.state = {
            currentPost :{
                category: 'react',
                title: '',
                author: '',
                body: '',
                id:'',
                voteScore:0
            },
            //editorState: EditorState.createEmpty()   
        }
     } 
    
    componentDidMount() {
        if (this.props.action==='edit'){
            this.props.fetchPost(this.props.postId)
                .then((action) => this.handleInputChange(action.loadedPost))
        } 
        // if (this.props.action==='edit'){
        //     this.props.fetchPost(this.props.postId)
        //         .then((action) => {
        //             this.handleInputChange(action.loadedPost)

        //             const blocksFromHTML = convertFromHTML(action.loadedPost.body)
        //             const stado = ContentState.createFromBlockArray(
        //                 blocksFromHTML.contentBlocks,
        //                 blocksFromHTML.entityMap
        //                 )
        //             this.setState({
        //                 editorState: EditorState.createWithContent(stado),
        //             })    
        //         })
        // }        
    }

    changeCategory = (newValue) => {
        this.handleInputChange({category: newValue})
    } 

    handleInputChange(newPartialInput) {
        this.setState(state => ({
            ...state,
            currentPost: {
                ...state.currentPost,
                ...newPartialInput
            }
        }))
        // console.log("newPartialInput")
        // console.log(this.state.currentPost)
    }

    // Editor is inserting <p></p>, so this 
    // function is to remove them
    // This is all hardcoded !!!
    // removePTag = (str) => {
    //     console.log(str)
    //     const lastIndex = str.lastIndexOf("</p>")
    //     str = str.substring(0, lastIndex).substring(3)
    //     console.log(str)
    //     return str
    // }

    // onEditorStateChange = (editorState) => {
    //     this.setState({
    //       editorState,
    //     })
    //   }

    handleSubmit = (e) => {
        e.preventDefault()

        const values = serializeForm(e.target, { hash: true })

        Alert.info('...saving data',alertOptions);
        console.log("About to save this")
        console.log(values)

        if (this.props.action==='add'){
            this.props.savePost(values)
                .then( () => {
                    Alert.success('Data saved successfully. Good Job!!!',alertOptions)
                    this.goPostEdit(values.id)
                })
                .catch((error) => Alert.error('Something went wrong when saving the data.',alertOptions))

        }else if (this.props.action==='edit'){
            values.id = this.props.postId
            const updatedRecord = {
                ...this.props.posts.loadedPost,
                ...values
            } 
            this.props.updatePost(updatedRecord)
                .then( () => {
                    Alert.success('Data saved successfully. Good Job!!!',alertOptions)
                })
                .catch((error) => Alert.error('Something went wrong when saving the data.',alertOptions))
        }    
    }

    goPostEdit(id) {
        this.props.history.push(`/postSave/edit/${id}`)
      }

    
    render() {
        const { action,postId } = this.props
        const {currentPost} = this.state
        // const {currentPost, editorState} = this.state
        return (    
            
            <div className="w3-card-4 w3-margin w3-white">
                <div>
                    <form onSubmit={this.handleSubmit} >
                        <div className="w3-container">
                            <div className="w3-row">
                                <div className="w3-col m1">
                                    <p className="home-button">
                                        <Link to={'/'} >Home</Link>
                                    </p>
                                </div>
                                {action!=='add' && (
                                    <div className="w3-col m1">
                                        <p className="return-link">
                                            <Link to={`/${currentPost.category}/${postId}`} >Details</Link>
                                        </p>
                                    </div>
                                )}
                                <div className="w3-col m1">
                                    {
                                        this.props.authenticated && (
                                            <p className="save-button">    
                                                <button type="submit" className="w3-button w3-padding-large w3-white w3-border">Save</button>
                                            </p>
                                        )
                                    }
                                </div>
                                <div className="w3-col m9 s12">
                                    <h3 className="w3-text-orange w3-right" >
                                        POST {action}
                                    </h3>
                                </div>
                            </div> 
                            <div className="w3-row">
                                <div className="w3-col m12 s12">
                                </div>
                            </div>   
                            <div className="w3-row">
                                <div className="w3-col m5 s12">
                                    <input  onChange={e => this.handleInputChange({title: e.target.value})} name="title" value={currentPost.title} placeholder='post title' className="w3-input" />
                                </div> 
                                <div className="w3-col m3">
                                    <input onChange={e => this.handleInputChange({author: e.target.value})} name="author" value={currentPost.author} placeholder='author' className="w3-input" />
                                </div>     
                                <div className="w3-col m2">
                                    <CategorySelect changeCategory={this.changeCategory} selectedCategory={currentPost.category} />
                                </div>      
                                <div className="w3-col m2 w3-right">
                                    <Votes enableChange={false} postId={currentPost.id}  voteScore={currentPost.voteScore}/>
                                </div>    
                            </div>
                            <div className="w3-row">
                                <div className="w3-col m12 s12">
                                </div>
                            </div>   
                            <div className="w3-row">  
                                <div className="w3-col m12 s12">
                                    {/* <Editor 
                                        editorState={editorState}
                                        wrapperClassName="demo-wrapper"
                                        editorClassName="demo-editor"
                                        onEditorStateChange={this.onEditorStateChange}
                                        />
                                    <input onChange={e => this.handleInputChange({body: e.target.value})} value={this.removePTag(draftToHtml(convertToRaw(editorState.getCurrentContent())))} 
                                        name="body"  
                                        className="w3-input" /> */}

                                    <textarea onChange={e => this.handleInputChange({body: e.target.value})} name="body" value={currentPost.body}  placeholder='body content' className="w3-input" />    
                                    
                                </div>    
                            </div>
                            
                        </div>
                    </form>
                </div>
            </div>
        )
    }

}

const mapStateToProps = ({ comments, posts, categories }) => ({
    posts
})
 
const mapDispatchToProps = (dispatch)  => (
        {
            savePost:   (values) => dispatch(savePost(values)),
            updatePost: (values) => dispatch(updatePost(values)),
            fetchPost: (postId) => dispatch(fetchPost(postId))            
        }
    )

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PostSave))