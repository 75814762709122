import * as TYPES from './actionTypes'

export const loginSuccess = (apiToken) => {
    return {
        type: TYPES.LOGIN_SUCCESS,
        apiToken
    }
}

export const loginFailure = (error) => {
    return {
        type: TYPES.LOGIN_FAILURE,
        error
    }
}


// export const login = (credentials) => dispatch => (
//     // loginApi(credentials)
//     //     .then(apiToken => dispatch(addToken(apiToken)))
// )

export const logout = () => {
    return {
        type: TYPES.LOGOUT
    }
}