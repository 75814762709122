import React from 'react'
import logo from '../images/logo_dark_h.png'

const Header = props => {

    return (
        <header className="w3-container w3-center w3-padding-small"> 
            {/* <h1><b>MY REACT BLOG</b></h1>
            <p>Welcome to the blog of <span className="w3-tag">Jaime Valencia</span></p> */}
            <img src={logo} />
        </header>
    )
}

export default Header