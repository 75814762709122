import React, { Component } from 'react'
import '../index.css'
import Home from './Home'
import {BrowserRouter,Route, Switch} from 'react-router-dom'
import Post from './Post'
import PostSave from './PostSave'
import CommentSave from './CommentSave'
import Header from './Header'
import Login from './Login'
import ProtectedRoute from './ProtectedRoute'
import PropTypes from 'prop-types'
import '../App.css'
import Alert from 'react-s-alert';

import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';


class Navigation extends Component {

  // to stop listening
  unsubscribe

  constructor(props) {
    super(props)
    this.state = {
      authenticated: false
    }
  }


  componentDidMount() {
      // Every time the state changes, log it
      // Note that subscribe() returns a function for unregistering the listener
      this.unsubscribe = this.props.store.subscribe(() => {
        let updatedState = this.props.store.getState()

        console.log("----- STATE CHNGED ------")
        console.log(updatedState)

        if (updatedState){
          console.log(updatedState.security)
          console.log("ALLOW the paths " +  this.state.authenticated)
          if (updatedState.security !== {} && updatedState.security.status ==='in'){
            this.setState({
              authenticated: true
            })
            //this.forceUpdate()
          } else{
            this.setState({
              authenticated: false
            })
          }
        }
      })  
  }

  componentWillUnmount(){
      this.unsubscribe()
  }


  render() {

    // let store = this.context.store
    console.log("IS IT AUTHENTICATED ")
    // console.log(store);
    console.log(this.state.authenticated)

    return (
        <BrowserRouter>
            <div className="w3-content" style={{maxwidth: 800}} >
              <Header /> 
              <Switch>
                  <Route exact path='/' render={(props) => (
                    <Home 
                        authenticated={this.state.authenticated}
                    />
                  )}/> 
                  <Route exact path='/signin' render={(props) => (
                    <Login 
                        authenticated={this.state.authenticated}
                    />
                  )}/> 
                  <Route exact path='/:category' render={(props) => (
                    <Home 
                        selCategory={props.match.params.category}
                      />
                  )}/>
                  <Route exact path='/postSave/edit/:postId' render={(props) => (
                    <ProtectedRoute 
                        postId={props.match.params.postId} 
                        action={"edit"} 
                        authenticated={this.state.authenticated}
                        path="/postSave/edit/:postId" 
                        component={PostSave} 
                    />
                    // <PostSave  
                    //   action='edit'
                    //   postId={props.match.params.postId} 
                    // />
                  )}/>
                  <Route exact path='/postSave/add' render={() => (
                    <ProtectedRoute 
                        action={"add"} 
                        authenticated={this.state.authenticated} 
                        path="/postSave/add" 
                        component={PostSave} 
                    />
                    // <PostSave  action='add' />
                  )}/>
                  <Route exact path='/:category/:postId' render={(props) => (
                    <Post 
                        authenticated={this.state.authenticated}
                        postId={props.match.params.postId}
                        onSavedPost={ () => {
                          props.history.push('/')
                        }}
                  />
                  )}/>
                  <Route exact path='/commentSave/:action/:commentId' render={(props) => (
                    <ProtectedRoute 
                        action={"edit"} 
                        commentId={props.match.params.commentId} 
                        authenticated={this.state.authenticated} 
                        path="/commentSave/:action/:commentId" 
                        component={CommentSave} 
                    />
                    // <CommentSave 
                    //     action='edit'
                    //     commentId={props.match.params.commentId}
                    //   />
                  )}/>
              </Switch>
              <Alert stack={{limit: 3}} />
            </div>
          </BrowserRouter>
    )
  }
}

Navigation.contextTypes = {
  store: PropTypes.object
}

export default Navigation

