import React from 'react'
import ReactDOM from 'react-dom'
import Navigation from './components/Navigation'
import registerServiceWorker from './registerServiceWorker'
import reducer from './reducers'
import {Provider} from 'react-redux'
import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import {loadState} from './utils/stateLoader'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const initialStatePersist = {
  security : loadState()
}

const store = createStore(
    reducer,
    initialStatePersist,
    composeEnhancers(
      applyMiddleware(thunk)
    )
  )

ReactDOM.render(
      <Provider store={store} >
        <Navigation store={store} />
      </Provider>
    , document.getElementById('root'))
registerServiceWorker()