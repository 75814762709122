import {configURL,HEADERS, addTokenToHeaders} from './configUtil'

export function logoutApi() {
    const url = `${configURL}/signout`;
    
    return fetch(url, { 
          headers: addTokenToHeaders(HEADERS)
        } )
      .then( (res) =>  res.json() )
  }

  export function loginApi(credentials) {
    const url = `${configURL}/signin`;
    
    return fetch(url, { 
                 method: 'POST',
                 headers: HEADERS,
                 body : JSON.stringify(credentials)             
                })
      .then( (res) =>  res.json() )
 }  