import { combineReducers } from 'redux'
import {comments} from './commentReducers'
import {categories} from './categoryReducers'
import {posts} from './postReducers'
import {security} from './securityReducers'

export default combineReducers({
    comments,
    posts,
    categories,
    security
  });